<template>
  <div>
    <hcc-dropdown :items="list" />
  </div>
</template>

<script>
import HccDropdown from '@/components/shared/HccDropdown/index.vue';


export default {
  components: { HccDropdown },

  data() {
    return {
      list: [{ value: '1', text: 'One' }, { value: '2', text: 'Two' }],
    };
  },

};
</script>

<style>
</style>
